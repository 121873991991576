import Cookie, { CookieAttributes } from 'js-cookie';

import { EnvConfig } from '@/env_config';

const { cookieDomain } = EnvConfig;

function isSecure(): boolean {
	if (typeof window !== 'undefined') {
		return window.location.protocol === 'https:';
	}
	return false;
}

/* eslint-disable no-undef */
const cookieOptions = {
	secure: isSecure(),
	domain: cookieDomain,
};
/* eslint-enable no-undef */

// 🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪
function get(key: string): string | undefined {
	return Cookie.get(key);
}

function set(key: string, value: string, options = {}): void {
	Cookie.set(key, value, { ...options, ...cookieOptions });
}

function remove(key: string, options?: CookieAttributes): void {
	const defaultOptions = { path: '/' };
	Cookie.remove(key, { ...defaultOptions, ...(options ?? cookieOptions) });
}
// 🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪🍪

const _exports = {
	get,
	set,
	remove,
};
export default _exports;
