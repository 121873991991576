import { animated } from 'react-spring';
import styled from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

import { PaletteColor, units } from '@calm-web/design-system';

export const NavMessageWrapper = styled(animated.div)<{
	$isError: boolean;
	$backgroundColor?: PaletteColor;
	$textColor?: PaletteColor;
}>`
	position: fixed;
	top: calc(${theme('navigation.height')}px + 5px);
	width: auto;
	max-width: 600px;
	left: 50%;
	transform: translateX(-50%);
	display: block;
	background: ${p =>
		ifProp(
			'$backgroundColor',
			palette(p.$backgroundColor as PaletteColor),
			ifProp('$isError', palette('errorBackground'), palette('successBackground')),
		)};
	text-align: center;
	padding: ${units(1, 2)};
	border-radius: ${theme('layout.borderRadius')};
	color: ${p => (p.$textColor ? palette(p.$textColor as PaletteColor) : 'white')};
	z-index: ${props => theme('modal.zIndex')(props) + 1};
`;
