import { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSpring } from 'react-spring';

import { useBannerMessage } from '@/hooks/store';
import { setBannerMessage } from '@/store/actions';

import { NavMessageWrapper } from './styles';

export const BANNER_MESSAGE_FLASH_TIME = 5000; // 5s

interface Props {
	children: ReactNode;
}

const BannerProvider: FC<Props> = ({ children }) => {
	const bannerMessage = useBannerMessage();
	const [showBannerMessage, setShowBannerMessage] = useState(false);
	const dispatch = useDispatch();

	const springPropsDown = useSpring({
		to: {
			opacity: showBannerMessage ? 1 : 0,
			top: showBannerMessage ? '78px' : '30px',
		},
		config: { mass: 5, tension: 350, friction: 40 },
	});

	useEffect(() => {
		let timeoutId: NodeJS.Timeout;
		if (showBannerMessage) {
			if (!bannerMessage.message) {
				// If ever we get a message from state that's empty, hide it
				setShowBannerMessage(false);
			}
		} else if (bannerMessage.message) {
			setShowBannerMessage(true);
			// Check to see if we should hide the message automatically
			if (bannerMessage.flash) {
				timeoutId = setTimeout(() => {
					setShowBannerMessage(false);
				}, BANNER_MESSAGE_FLASH_TIME);
			}
		}
		return () => {
			if (timeoutId) {
				setShowBannerMessage(false);
				clearTimeout(timeoutId);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bannerMessage]);

	useEffect(() => {
		return () => {
			dispatch(setBannerMessage({ message: '', flash: false, isError: false }));
		};
	}, [dispatch]);

	return (
		<>
			<NavMessageWrapper
				data-testid="nav-banner"
				$isError={bannerMessage.isError}
				style={springPropsDown}
				role={bannerMessage.isError ? 'alert' : 'status'}
				$backgroundColor={bannerMessage.backgroundColor}
				$textColor={bannerMessage.textColor}
			>
				{bannerMessage.message}
			</NavMessageWrapper>
			{children}
		</>
	);
};

export default BannerProvider;
