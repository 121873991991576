import type { AnalyticsSnippet } from '@segment/analytics-next';

import { retryUntilAvailable } from '@/hooks/analytics/retryUntilAvailable';
import { LogEventArgs } from '@/hooks/analytics/types';

import { calmLogger } from '../calmLogger';

declare global {
	interface Window {
		analytics: AnalyticsSnippet;
	}
}

export const initSegmentUser = async (): Promise<void> => {
	const anonymousId = window.analytics.user().anonymousId();
	retryUntilAvailable(
		() => window.analytics.identify(anonymousId),
		() => Boolean(window.analytics),
	).catch(err => calmLogger.error('Error in initSegmentUser', {}, err));
};

export const logSegmentEvent = async ({ eventName, eventProps }: LogEventArgs): Promise<void> => {
	retryUntilAvailable(
		() => window.analytics.track(eventName, eventProps),
		() => Boolean(window.analytics),
	).catch(err => calmLogger.error('Error in logSegmentEvent', {}, err));
};
