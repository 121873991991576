import { datadogRum } from '@datadog/browser-rum';

export const initDatadogRum = (): void => {
	datadogRum.init({
		applicationId: 'dd277c22-c422-403d-a616-df277cb73acf',
		clientToken: 'pub10464f8ecda6f169a56ff8491106f12b',
		site: 'datadoghq.com',
		service: 'web-partner-portal',
		version: process.env.BUILD_ID,
		env: process.env.LOG_ENV,
		sessionSampleRate: 100,
		trackUserInteractions: true,
		beforeSend: event => {
			// this is so calmjwts in the URL are not sent to Datadog RUM
			// we should be able to remove this once we have migrated to Auth0
			event.view.url = event.view.url.replace(/calmjwt=[^&]+/, 'calmjwt=REDACTED');

			// true means the event should not be discarded. false would mean discard the event
			return true;
		},
	});
};
