const MAX_RETRIES = 10;
const RETRY_DELAY = 200;

export const retryUntilAvailable = async <T>(
	fn: () => Promise<T>,
	test: () => boolean,
	retriesRemaining = MAX_RETRIES,
	retryDelay = RETRY_DELAY,
): Promise<T | undefined> => {
	if (test()) {
		return fn();
	}

	if (retriesRemaining > 0) {
		return new Promise(resolve => {
			setTimeout(resolve, retryDelay);
		}).then(() => retryUntilAvailable(fn, test, retriesRemaining - 1, retryDelay));
	}

	// We treat the test never passing as a resolve rather than reject
	// This is because for analytics, the reason the test would fail is likely intentional
	// due to the user not opt-ing in for GDPR
	return Promise.resolve(undefined);
};
